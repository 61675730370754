import type { PropsWithChildren, ReactNode } from "react";
import type { BrandProps } from "~/components/nav-bar";
import NavBar from "~/components/nav-bar";

interface Props extends PropsWithChildren<unknown> {
  menuItems?: ReactNode;
  brand?: BrandProps;
  rightMenuItems?: ReactNode;
  id?: string;
}

export default function NavWrapper({
  menuItems,
  brand,
  rightMenuItems,
  id,
  children
}: Props) {
  return (
    <>
      <NavBar secondary rightId={id} brand={brand} rightMenu={rightMenuItems}>
        {menuItems}
      </NavBar>
      {children}
    </>
  );
}
